@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

html {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Work Sans', sans-serif;
}

div#root {
    height: 100%;
}

.container {
    height: 100%;
    background: #f1eeee;
    overflow: auto;
    position: relative;
}

.home_page, .api_list_page {
    width: 100%;
    max-width: 1261.2px;
    margin: 0 auto;
    height: 100%;
    padding: 25px 78px;
}

.home_welcome h1 {
    color: #002E71;
    margin: 0 auto 24px auto;
    font-size: 33px;
    max-width: 552px;
}

.home_flex {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.home_flex_column {
    flex-direction: column;
}

.login_form {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(248 245 245 / 75%);
    z-index: 2;
}

.home_welcome > p {
    color: #626262;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 84px 0;
    padding: 0 40px;
    max-width: 600px;
}

.home_action p {
    color: #002E71;
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 19.2px 0;
}

.home_action p span {
    color: #9b9b9b;
}

.home_action p.small {
    font-size: 16px;
    margin-top: 30px;
}

.btn_blue {
    padding: 0 24px;
    height: 36px;
    background: #002E71;
    color: #fff;
    display: inline-flex;
    align-items: center;
    border-radius: 36px;
    font-size: 15.6px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.home_welcome {
    text-align: center;
}

.test_form h3 {
    margin: 0;
    color: #fff;
    font-size: 22.8px;
    font-weight: 600;
    line-height: 1.5;
}

.test_form {
    text-align: center;
    padding: 28.8px;
    border-radius: 14.4px;
    background: #002E71;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 384px;
}

.diviser {
    height: 2px;
    width: 100%;
    background: #fff;
    margin: 31.2px 0;
}

.test_form p {
    color: #fff;
    margin: 0;
    font-size: 15.6px;
    font-weight: 300;
    margin-bottom: 4.8px;
}

.send_request_input {
    width: 100%;
    display: block;
    height: 42px;
    background: #f8f5f5;
    border: 0;
    border-radius: 35px;
    padding: 0 30px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 25.2px;
    font-size: 15.6px;
}

span.send_request_info {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 64px;
}

.btn_white {
    padding: 0 24px;
    height: 36px;
    background: #fff;
    color: #002E71;
    display: inline-flex;
    align-items: center;
    border-radius: 30px;
    min-width: 132px;
    justify-content: center;
    font-size: 16.8px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}

.test_form p span {
    font-weight: 500;
    margin-right: 5px;
}

.test_form p + input.send_request_input {
    margin-top: 38.4px;
}

img.logo_blue {
    display: none;
}

.login_form_contaire {
    text-align: center;
    padding: 24px;
    border-radius: 12px;
    background: #002E71;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;
    width: 100%;
}

.login_form_contaire h1 {
    color: #fff;
    margin: 0 0 48px 0;
}

.login_form_contaire input.send_request_input + .btn_white {
    margin-top: 5px;
}

.login_form_contaire a {
    margin-top: 15px;
    font-size: 12px;
    text-decoration: underline;
    color: rgb(255 255 255 / 75%);
    cursor: pointer;
}

.home_page .home_flex > h2, .api_list_page .home_flex > h2 {
    color: #020287;
    font-weight: 800;
    font-size: 35px;
    max-width: 630px;
    margin: 0 auto 0 auto;
    text-align: center;
    line-height: 1.25;
}

.api_item {
    height: 50px;
    display: flex;
    background: #f8f5f5;
    border: 1px solid #002E71;
    border-radius: 6px;
    padding: 5px;
    margin: 0 45px 16px 0;
    align-items: center;
    flex: 0 0 calc((100% - 90px) / 3);
    cursor: pointer;
    color: #002E71;
    text-decoration: none;
}

.api_item span {
    color: #002E71;
    font-weight: 500;
    font-size: 14px;
}

.api_icon {
    height: 38px;
    flex: 0 0 38px;
    background: #002E71;
    border-radius: 6px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.api_icon img {
    height: 25px;
}

.api_list {
    display: flex;
    flex-wrap: wrap;
    max-width: 840px;
    margin: 0 auto 60px auto;
    width: 100%;
}

.api_item:nth-child(2n) {
    margin-right: 0;
}

.api_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.api_info span {
    color: #626262;
    font-size: 12px;
    font-weight: 500;
}

.api_info span + span {
    margin-top: 8px;
}

.api_info span + .btn_blue.btn_large {
    margin-top: 14px;
}

.footer {
    color: #a0a0a0;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
}

.navbar_blue {
    height: 96px;
    background: #002E71;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 62px;
}

.navbar_transparent {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 62px;
    padding: 0 30px;
    border-bottom: 1px solid #002E71;
    position: relative;
}

.title_text h2 {
    color: #020287;
    font-weight: 800;
    font-size: 43px;
    margin: 0 auto 0 auto;
    text-align: center;
    line-height: 1.25;
}

.title_text p {
    color: #020287;
    font-weight: 400;
    font-size: 18px;
    margin: 18px auto 30px auto;
    text-align: center;
    line-height: 1.25;
}

.title_text {
    margin: 0 0 54px 0;
}

.send_request_boxs {
    justify-content: space-between;
    display: flex;
    max-width: 718px;
    margin: 0 auto 50px auto;
}

.send_request_box {
    flex: 0 0 calc((100% - 77px) / 2);
    background: #d4d5ee;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.send_request_header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #002E71;
    font-size: 23px;
    font-weight: 600;
}

.send_request_body {
    background: #002E71;
    border-radius: 14px;
    height: 100%;
    padding: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#request_batch_input {
    display: none;
}

.batch_input {
    background: #f8f5f5;
    border-radius: 8px;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.batch_input span {
    font-size: 11px;
    font-weight: 400;
    color: #626262;
}

.send_request_body span.send_request_info {
    font-size: 9px;
    margin-bottom: 0;
}

.send_request_body .btn_white {
    margin: 0 auto;
    font-size: 14px;
    height: 30px;
    padding: 0 20px;
    min-width: 110px;
}

.btn_white.btn_outline {
    color: #fff;
    background: #002E71;
    border: 1px solid #fff;
}

.send_request_body .btn_white + .btn_white {
    margin-top: 14px;
}

.send_request_body span.send_request_info + .btn_white {
    margin-top: 12px;
}

.send_request_body input.send_request_input {
    height: 35px;
    padding: 0 25px;
    font-size: 13px;
    margin-bottom: 20px;
    color: #050b80;
    font-weight: 500;
}

.send_request_body input.send_request_input::placeholder {
    font-weight: 400;
}
.send_request_body input.send_request_input:-ms-input-placeholder {
    font-weight: 400;
}

.send_request_body input.send_request_input::-ms-input-placeholder {
    font-weight: 400;
}

.send_request_body input.send_request_input ~ .btn_white {
    margin-top: 25px;
}

.btn_blue.btn_outline {
    border: 1px solid #002E71;
    background: transparent;
    color: #002E71;
}

.send_request_body .btn_white {
    font-weight: 600;
}

.center_btn {
    display: flex;
    justify-content: center;
}

.navbar_blue + .content, .navbar_transparent + .content {
    padding: 50px 0 20px 0;
}

.sidebar {
    flex-direction: column;
    display: flex;
    flex: 0 0 385px;
}

.sidebar_list {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 0 67px;
}

.content.content_flex {
    display: flex;
}

.sidebar .btn_blue {
    margin: 0 auto;
    font-size: 14px;
    height: 38px;
    padding: 0 17px;
    margin-bottom: 29px;
    font-weight: 600;
    min-width: 250px;
    justify-content: center;
}

.sidebar_list span {
    font-weight: 600;
    font-size: 25px;
    color: #969696;
    margin-bottom: 26px;
}

.sidebar_list span.active {
    color: #002E71;
}

.sidebar_list svg {
    height: 28px;
    width: 28px;
}

.table_request, .table_log {
    width: 100%;
    padding-right: 67px;
}

table {
    width: 100%;
    border: 1px solid #002E71;
    border-radius: 8px;
    border-spacing: 0;
    overflow: hidden;
    margin: 0 auto;
    max-width: 640px;
}

td, .table_log td:last-child {
    padding: 8px 18px;
    font-size: 13px;
    color: #002E71;
    border-bottom: 1px solid #002E71;
    font-weight: 500;
}

.table_log tr:last-child td:last-child {
    border-bottom: 0;
}

td:last-child {
    color: #969696;
}

tr:nth-child(2n+1) {
    background: #f8f5f5 !important;
}

tr:nth-child(2n) {
    background: #d8d9f2 !important;
}

tr.logs_duplicates {
    background: #e7e6f0 !important;
}

tr:last-child td {
    border-bottom: 0;
}

.batch_input svg {
    height: 28px;
    width: 28px;
    margin-right: 6px;
}

.table_log_title h2 {
    margin: 0 auto 0 8px;
    color: #002E71;
}

.table_log_title {
    display: flex;
    max-width: 860px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 16px;
}

.table_log table {
    max-width: 860px;
}

.table_log thead td {background: #002E71;color: #fff !important;}

.table_log {
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
}

.table_log_title .btn_blue.btn_outline {
    font-weight: 600;
    font-size: 14px;
    padding: 0 18px;
    height: 32px;
}

.table_log .btn_blue {
    font-weight: 500;
    font-size: 13px;
    padding: 0 16px;
    height: 28px;
}
.license_footer + .center_btn .btn_blue.btn_outline {
    font-weight: 600;
    transition: all 0.2s ease;
}

.api_list_page .home_flex > h2 {
    padding-top: 25px;
}

.api_list_page .footer {
    padding-bottom: 25px;
}

.api_list_page {
    padding: 0 78px;
}

.api_list_page .home_flex_column {
    justify-content: initial;
}
span.send_request_label {
    font-size: 10px;
    color: #fff;
    margin-bottom: 4px;
    padding-left: 25px;
}
.table_log thead td.param {
    text-transform: capitalize;
}

.json-to-table table {
    border-collapse: inherit !important;
}

.json-to-table div {
    padding: 0 0 5px 0 !important;
}

.json-to-table td, .json-to-table th {
    border: 0 !important;
    border-bottom: 1px solid #002E71 !important;
}

.json-to-table tr:last-child > td, .json-to-table tr:last-child > th {
    border-bottom: 0 !important;
}
.json-to-table table {
    font-family: 'Work Sans', sans-serif !important;
}
.json-to-table table strong {
    font-weight: 500 !important;
}
td a {
    text-decoration: none;
    color: #002E71;
}
.count_sticker {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    width: 180px;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    display: none;
}

.count_label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #002E71;
    background: #d0d0e9;
    padding: 10px 15px;
    border: 2px solid #002E71;
    width: 39px;
    height: 235px;
    border-right: 0;
    border-radius: 12px 0 0 12px;
    overflow: hidden;
    position: absolute;
    right: 0;
}

.count_label span {
    transform: rotate(-90deg);
    position: absolute;
    width: 235px;
    right: -100px;
    margin-top: 80px;
}
.count_container {
    height: 235px;
    background: #002E71;
    color: #fff;
    padding: 0 45px 0 25px;
    border-radius: 20px 0 0 20px;
    width: 255px;
    position: relative;
    left: 255px;
    transition: left ease 0.5s;
}

.count_container h5 {
    margin: 12px 0 18px 0;
    font-weight: 500;
}

.count_container p {
    margin: 0 0 15px 0;
    font-size: 27px;
    font-weight: 300;
}

.count_container p span {
    font-size: 19px;
    font-weight: 200;
}

.count_container p strong {
    font-size: 36px;
    font-weight: 300;
    margin-right: 4px;
    text-transform: capitalize;
}

p.count_info {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 4px;
    letter-spacing: 0.5px;
}

p.count_info span {
    font-size: 16px;
    font-weight: 600;
}

.count_bar {
    height: 12px;
    width: 100%;
    background: #fff;
    margin: 20px 0 0 0;
    border-radius: 12px;
}

.count_disponible_bar {
    height: 12px;
    width: 0;
    background: #909cec;
    margin-top: 20px;
    border-radius: 12px;
}

.count_label:hover + .count_container, .count_container:hover {
    left: 0;
}
.table_logs {
    position: absolute;
    left: -100%;
    top: -100%;
}

.table_logs .table_request {
    padding: 0;
}
.navbar_blue .btn_white, .navbar_transparent .btn_blue {
    position: relative;
    z-index: 1;
}
img.country_icon {
    height: 20px;
    margin-left: auto;
    padding-left: 5px;
}
.home_page .home_flex > p, .api_list_page .home_flex > p {
    color: #020287;
    font-weight: 400;
    font-size: 16px;
    max-width: 650px;
    margin: 18px auto 30px auto;
    text-align: center;
    line-height: 1.25;
}
img.logo {
    height: 50px;
}
span.navbar_btn_outline {
    color: #fff;
    font-size: 12px;
    display: block;
    margin-bottom: 6px;
}

span.navbar_btn_outline + .btn_white {
    height: 28px;
    padding: 0 18px;
    font-size: 14px;
    font-weight: 600;
}

.btn_outline_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.username {
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.username svg {
    margin-left: 6px;
}

.username {
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.username svg {
    margin-left: 6px;
}

.navbar_transparent .username {
    color: #020287;
}

.navbar_transparent .username svg {
    fill: #020287;
}
.username_dropdown {
    background: rgb(0 46 113 / 90%);
    position: absolute;
    right: 0;
    top: 110px;
    width: 350px;
    border-radius: 18px;
    padding: 20px 20px;
    color: #fff;
    max-width: 435px;
    overflow: auto;
    z-index: 2;
}

.username_dropdown h3 {
    margin: 0 0 12px 0;
    font-weight: 500;
    font-size: 20px;
    padding: 0 10px;
}

.username_info p {
    font-size: 15px;
    margin: 0 0 10px 0;
    font-weight: 500;
}

.username_info p span {
    font-weight: 300;
}

.username_info {
    /* border-bottom: 1px solid #fff;
    margin-bottom: 20px; */
    padding: 0 10px 8px 10px;
}

.username_api h4 {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 15px;
}

.username_api {
    padding: 0 10px;
    margin-bottom: 20px;
}

.username_api p {
    margin: 0;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 8px;
}

.username_api p.flex {
    display: flex;
    justify-content: space-between;
}

.username_dropdown .btn_white {height: 28px;padding: 0 18px;min-width: 100px;font-size: 14px;color: #002E71 ;}

.btn_username_container {
    text-align: center;
    padding: 20px 0 6px 0;
}

.navbar_blue .username_dropdown {
    right: 62px;
}
.methods_list {
    height: 230px;
    overflow: auto;
}

span.send_request_error {
    background: #f44336;
    color: #fff;
    padding: 4px 12px;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: -47px;
}
.upload_icon img {
    height: 24px;
    margin-right: 6px;
    display: block;
}

.methods_list::-webkit-scrollbar {
    width: 10px;
  }
  
  .methods_list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }
  
  .methods_list::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

.api_container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 26px;
    width: 100%;
}

.api_container > h1 {
    width: 100%;
    margin: 0 0 14px 0;
    color: #002E71;
    font-weight: 600;
    font-size: 24px;
}

.api_container .api_item:nth-child(2n) {
    margin-right: 45px;
}

.api_container .api_item:nth-child(3n+1) {
    margin-right: 0;
}

.countries {
    display: flex;
    width: 100%;
    max-width: 25px;
    overflow: hidden;
    margin-left: auto;
    transition: ease 0.5s;
}

.countries_1:hover {
    max-width: 25px;
}
.countries_2:hover {
    max-width: 50px;
}
.countries_3:hover {
    max-width: 75px;
}
.countries_4:hover {
    max-width: 100px;
}
.countries_5:hover {
    max-width: 125px;
}
.countries_6:hover {
    max-width: 150px;
}
.footer a {
    color: #a0a0a0;
    text-decoration: none;
}
.api_list > .api_item {
    flex: 0 0 calc((100% - 45px) / 2);
}
.api_list.api_list_demo {
    max-width: 542px;
}
.title_text .method_description {
    max-width: 670px;
    padding: 0 10px;
}
.navbar_transparent .btn_blue + .btn_blue, .navbar_blue .btn_white + .username {
    margin-left: 30px;
}

.navbar_transparent .btn_blue, .navbar_blue .btn_white {
    margin-left: auto;
}
.btn_blue svg, .btn_white svg {margin-right: 8px;}
.btn_white.btn_white_error {
    color: #f44336;
}
.btn_white.btn_white_loading {
    opacity: 0.8;
}
.btn_blue_check_container {
    border: 1px solid #002E71;
    border-radius: 20px;
    padding: 3px;
    margin-left: auto;
    position: relative;
    z-index: 1;
}

.btn_blue_check_container .btn_blue.btn_checkbox {
    padding: 0 14px;
    height: 28px;
    font-size: 13px;
    margin-left: 4px;
    background: transparent;
    color: #002E71;
}

.btn_blue_check_container .btn_blue.btn_checkbox:first-child {
    margin-left: 0;
}

.btn_blue_check_container + .btn_blue {
    margin-left: 10px;
}

.btn_blue.btn_checkbox.selected, .btn_blue.btn_checkbox.selected:hover {
    background: #002E71;
    color: #fff;
}

.btn_blue.btn_checkbox:hover {
    background: #d0d0e9;
}

.btn_blue + .btn_blue_check_container, .btn_white + .btn_blue_check_container {
    margin-left: 30px;
}

.btn_blue_check_container + .username {
    margin-left: 30px;
}

.navbar_blue .btn_blue_check_container {
    border-color: #fff;
}

.navbar_blue .btn_blue.btn_checkbox.selected, .navbar_blue 
 .btn_blue.btn_checkbox.selected:hover {
    background: #fff;
    color: #002E71;
}

.navbar_blue .btn_blue_check_container .btn_blue {
    color: #fff;
}

.navbar_blue .btn_blue.btn_checkbox:hover {
    background: #f44336;
}
.btn_blue_check_container + .btn_outline_container {
    margin-left: 30px;
}
.api_info span + span a {
    text-decoration: none;
    color: #002E71;
}

.home_action p span + a {
    color: #002E71;
    text-decoration: none;
}
.send_request_body span.send_request_error {
    margin: 12px auto -10px auto;
}
.btn_disable {
    opacity: 0.5;
    cursor: default;
}
td span {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
    display: block;
    max-width: 200px;
    margin: 0 auto;
}
input.send_request_input[type="file"] {
    display: none;
}

input.send_request_input[type="file"] + label {
    height: 35px;
    padding: 0 5px;
    font-size: 13px;
    margin-bottom: 16px;
    background: #f8f5f5;
    border-radius: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

input.send_request_input[type="file"] + label div {
    background: #002E71;
    color: #fff;
    height: calc(100% - 6px);
    border-radius: 29px;
    display: flex;
    align-items: center;
    padding: 0 11px;
    white-space: nowrap;
    min-width: 115px;
    justify-content: center;
}

input.send_request_input[type="file"] + label span {
    width: 100%;
    padding: 0 5px 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
}
.btn_navbar {
    padding: 0 24px 0 14px;
    height: 34px;
    font-size: 16px;
    font-weight: 500;
}


.table_request {
    max-width: calc(100% - 325px);
    overflow: auto;
    width: auto;
    margin: 0 auto;
}

.json-to-table {
    border-radius: 8px;
    width: 100%;
    overflow: auto;
    border: 1px solid #002E71;
}
.json-to-table > table {
    border: 0;
    min-width: 640px;
}

.count_sticker:hover {
    width: 255px;
}
.home_page.signup_page {
    height: initial;
}
.test_form.signup_form {
    width: 100%;
    max-width: 410px;
}
.signup_input_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.signup_input_flex input.send_request_input {
    flex: 0 0 calc((100% - 15px) / 2);
}
input.send_request_input.send_request_input_err {
    outline: 2px solid #f44336;
}
.filter_dropdown_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 840px;
    margin: 0 auto 24px auto;
    width: 100%;
}

.filter_dropdown_container.filter_dropdown_container_demo {
    max-width: 542px;
}

.filter_dropdown_title {
    color: #ab4310;
    padding: 5px 18px;
    background: #f6b697;
    border-radius: 12px;
    border: 1px solid #FC6C25;
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: flex;
    padding-right: 8px;
    align-items: center;
}

.filter_dropdown {
    font-size: 14px;
    min-width: 155px;
    position: relative;
}

.filter_dropdown_list {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    padding-top: 15px;
    margin-top: 15px;
    border: 1px solid #7f88ad;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}

.filter_dropdown_item {
    padding: 5px 14px;
    border-bottom: 1px solid #7f88ad;
    color: #2f43b2;
    cursor: pointer;
}

.filter_dropdown_item:last-child {
    border-bottom: 0;
}

.filter_dropdown_item:hover {
    background: #e9e9f1;
}
.filter_dropdown_title svg {
    margin-left: auto;
    fill: #f44336;
}
.btn_white svg {
    fill: #020287;
}
.center_btn a.btn_blue.btn_outline + a.btn_blue.btn_outline {
    margin-left: 20px;
}
.send_request_box.plan_box .send_request_header {
    background: #002E71;
    color: #fff;
}

.send_request_box.plan_box {
    background: #002E71;
    padding: 4px;
}

.send_request_box.plan_box .send_request_body {
    background: #fff;
    width: 100%;
    align-items: center;
}

p.plan_call_count {
    color: #020287;
    font-weight: 800;
    font-size: 36px;
    margin: 0;
    margin-bottom: 15px;
}

p.plan_price {
    margin: 0 0 0 0;
    display: flex;
    color: #020287;
    font-weight: 500;
    font-size: 23px;
    align-items: flex-end;
}

p.plan_price + p.plan_price {
    font-size: 16px;
    margin-top: 4px;
}

span.plan_currency {
    display: block;
    margin: 0 3px;
}

span.plan_month {
    font-size: 15px;
    color: #7f88ad;
    font-weight: 400;
    margin-bottom: 2px;
}

p.plan_price + p.plan_price span.plan_month {
    font-size: 11px;
}

.send_request_box.plan_box .btn_blue {
    margin-top: 24px;
}
.license_footer + .center_btn .btn_blue.btn_outline:hover {
    background: #002E71;
    color: #fff;
}
.send_request_input.send_request_select {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Work Sans', sans-serif;
    color: #757575;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    border: 2px solid #f8f5f5;
    padding-right: 40px;
    margin-bottom: 20px;
}

.send_request_select_container {
    position: relative;
}

.send_request_select_list {
    position: absolute;
    top: 0;
    padding-top: 35px;
    border-radius: 20px;
    background: #fff;
    width: 100%;
    overflow: hidden;
    display: none;
    border: 1px solid #909cec;
}

.send_request_select_item {
    height: 30px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-bottom: 1px solid #909cec;
    color: #020287;
    cursor: pointer;
}

.send_request_select_container.active .send_request_select_list {
    display: block;
}


.send_request_select_container.active .send_request_input.send_request_select {
    background: #d8d9f2;
    color: #002E71;
    border: 2px solid #020287;
}

.send_request_select_item:last-child {
    border-bottom: 0;
}

.send_request_select_item:hover {
    background: #d8d9f2;
}
.send_request_input.send_request_select.send_request_select_selected {
    color: #050b80;
    font-weight: 500;
}

.send_request_input.send_request_select svg {
    position: absolute;
    right: 10px;
}
.send_request_body input.send_request_input:focus {
    border: 2px solid #020287;
    outline: none;
}

#export_pdf {
    height: 1123px;
    width: 794px;
    background: #fff;
    position: absolute;
    top: 0;
    z-index: -1;
}

.pdf_header {
    display: flex;
    background: #002E71;
    padding: 20px 40px;
    align-items: center;
}

.header_text {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding-right: 10px;
}

span.header_text_title {
    font-size: 22px;
    font-weight: 300;
}

span.header_text_date {
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
}

svg.username_dropdown_close {
    position: absolute;
    right: 20px;
    top: 20px;
    fill: #fff;
    cursor: pointer;
    display: none;
}

.btn_blue_check_container.request_mobile_type {
    display: none;
}

.license_footer h6 {
    color: #002E71;
    font-weight: 800;
    font-size: 15px;
    margin: 0 auto 0 auto;
    text-align: center;
    line-height: 1.25;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.license_footer p {
    color: #020287;
    font-weight: 400;
    font-size: 13px;
    margin: 8px auto 35px auto;
    text-align: center;
    line-height: 1.25;
}

.license_footer {
    max-width: 670px;
    margin: 0 auto;
}

.sidebar .btn_blue.btn_sub_item {
    margin-bottom: 0;
    border-radius: 0;
    background: transparent;
    color: #002E71;
}

.sidebar .btn_blue.btn_sub_item + a.btn_blue.btn_outline {
    margin-top: 29px;
}

.btn_blue.btn_sub_item.btn_sub_item_last {
    border-radius: 0 0 18px 18px;
}

.btn_username_container .btn_blue_check_container {
    display: none;
}

.sidebar .btn_blue.btn_sub_item:hover {
    background: #d8d9f2;
}

svg.login_form_contaire_close {
    position: absolute;
    right: 20px;
    top: 20px;
    fill: #fff;
    cursor: pointer;
    display: none;
}

.license_footer h6 svg {
    margin-right: 5px;
    height: 18px;
    width: 18px;
    margin-top: -2px;
    fill: #626262;
}

.license_footer h6 svg.checked {
    fill: #020287;
}

.license_footer.license_footer_error h6 {
    color: #f44336;
}

.license_footer.license_footer_error h6 svg {
    fill: #f44336;
}

.btn_blue.btn_sub_primary {
    margin-bottom: 0;
}

.download_list_btn {
    display: none;
    flex-direction: column;
    min-width: 250px;
    margin: 0 auto;
    border-radius: 0 0 18px 18px;
    border: 1px solid #002E71;
    border-top: 0;
    padding-top: 19px;
    margin-top: -19px;
}

.download_list_btn + a.btn_blue.btn_outline {
    margin-top: 30px;
}

.download_list_btn.active {
    display: flex;
}

.expand_download_list {
    display: flex;
    align-items: center;
    background: #f1eeee;
    border-radius: 50px;
    position: absolute;
    right: 10px;
    top: -31px;
    cursor: pointer;
}

.expand_download_list svg {
    margin-right: 0;
    fill: #002E71;
}

.btn_blue.btn_sub_primary {
    position: relative;
    padding-right: 32px;
    box-shadow: 0px 0px 0 1px #002E71;
}

.btn_blue.btn_sub_primary.active + .expand_download_list_container .expand_download_list {
    transform: rotate(180deg);
}

.expand_download_list_container {
    position: relative;
    min-width: 250px;
    margin: 0 auto;
}

.btn_white.btn_social {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
}

.btn_social_container {
    margin: 12px 0 8px 0;
}

.btn_white.btn_social svg {
    height: 22px;
    margin-right: 14px;
    margin-left: -8px;
}

@media only screen and (max-width: 430px) {
    .api_list_page {
        padding: 0 25px;
    }
    
    .home_page .home_flex > h2, .api_list_page .home_flex > h2 {
        font-size: 23px;
    }
    
    .home_page .home_flex > p, .api_list_page .home_flex > p {
        font-size: 13px;
    }
    
    .filter_dropdown_title {
        padding: 3px 12px;
        border-radius: 6px;
    }
    
    .api_container > h1 {
        font-size: 22px;
    }
    
    .api_item {
        flex: 0 0 100%;
    }
    
    .api_container .api_item:nth-child(2n) {
        margin-right: 0;
    }
    
    .navbar_transparent, .navbar_blue {
        margin: 0;
        padding: 0 25px;
        height: 64px;
    }
    
    .navbar_transparent > .btn_blue_check_container,
    .navbar_blue > .btn_blue_check_container {
        display: none;
    }

    .btn_navbar {
        display: none;
    }
    
    img.logo {
        height: 33px;
    }
    
    .username_dropdown {
        background: #002E71;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: column;
    }
    
    .methods_list {
        height: 100%;
    }
    
    .btn_username_container {
        border-top: 1px solid #fff;
        margin-top: 20px;
        display: flex;
        align-items: center;
    }

    .api_list_page .footer {
        align-items: center;
        flex-wrap: wrap;
    }
    
    .api_list_page .footer a {
        flex: 0 0 50%;
    }
    
    .api_list_page .footer a + a {
        text-align: right;
    }
    
    .api_list_page .footer span {
        text-align: center;
        display: block;
        width: 100%;
        margin-top: 18px;
    }
    .api_list_page .footer {
        align-items: center;
        flex-wrap: wrap;
    }
    
    .api_list_page .footer a {
        flex: 0 0 50%;
    }
    
    .api_list_page .footer a + a {
        text-align: right;
    }
    
    .api_list_page .footer span {
        text-align: center;
        display: block;
        width: 100%;
        margin-top: 18px;
    }
    
    .api_list {
        margin-bottom: 10px;
    }

    svg.username_dropdown_close {
        display: block;
    }

    .btn_username_container .btn_blue_check_container {
        border-color: #fff;
        margin-left: 0;
        margin-right: auto;
        display: block;
    }

    .btn_username_container .btn_blue_check_container .btn_blue.btn_checkbox {
        color: #fff;
    }

    .btn_username_container .btn_blue.btn_checkbox.selected {
        color: #002E71;
        background: #fff;
    }

    .title_text h2 {
        font-size: 23px;
    }

    .btn_navbar {
        display: none;
    }
    
    .navbar_blue + .content, .navbar_transparent + .content {
        padding: 30px 25px;
    }
    
    p.method_description {
        font-size: 13px;
    }
    
    .title_text {
        margin: 0;
    }
    
    .center_btn {
        flex-direction: column;
        align-items: center;
    }
    
    .center_btn a.btn_blue.btn_outline + a.btn_blue.btn_outline {
        margin-left: 0;
    }
    
    .license_footer + .center_btn .btn_blue.btn_outline:first-child {
        margin-bottom: 15px;
    }
    
    .license_footer + .center_btn .btn_blue.btn_outline {
        height: 32px;
        font-size: 14px;
        padding: 0 18px;
    }

    .btn_blue_check_container.request_mobile_type {
        background: #d4d5ee;
        border: 0;
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
    }
    
    .btn_blue_check_container.request_mobile_type .btn_blue.btn_checkbox {
        border-radius: 8px;
        flex: 0 0 50%;
        justify-content: center;
        font-size: 15px;
        height: initial;
        padding: 8px 0;
        margin: 0;
    }

    .send_request_header {
        display: none;
    }
    
    .send_request_box {
        display: none;
    }
    
    .send_request_box.send_request_box_show_mobile {
        display: block;
        flex: 0 0 100%;
        border-radius: 10px;
    }

    .count_sticker {
        display: none;
    }

    .send_request_box.plan_box {
        display: flex;
        flex: 0 0 100%;
        margin-top: 30px;
        position: relative;
    }
    
    .send_request_boxs {
        flex-wrap: wrap;
    }
    
    span.plan_month {
        display: none;
    }
    
    .send_request_box.plan_box .send_request_body {
        align-items: flex-start;
        padding: 18px;
        background: #d0d0e9;
    }
    
    .send_request_box.plan_box .btn_blue {
        position: absolute;
        right: 20px;
        margin-top: 0;
        bottom: 20px;
    }
    
    p.plan_call_count {
        line-height: 1;
        margin-bottom: 5px;
    }
    
    p.plan_price + p.plan_price {
        color: #969696;
    }

    .table_log {
        overflow: auto;
    }
    
    .table_log .btn_blue {
        width: 132px;
    }

    .table_log {
        overflow: auto;
    }
    
    .table_log .btn_blue {
        width: 132px;
    }
    
    .table_log_title {
        flex-direction: column;
        align-items: center;
    }
    
    .table_log_title h2 {
        margin: 0 auto 20px auto;
    }

    div#export_pdf {
        display: none;
    }

    div#export_pdf {
        display: none;
    }
    
    .navbar_blue + .content, .navbar_transparent + .content {
        flex-direction: column;
    }
    
    .table_request {
        max-width: inherit;
        overflow: auto;
        width: 100%;
        padding-right: 0;
    }

    .api_list.api_list_demo {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    
    .api_list.api_list_demo > .api_item {
        flex: 0 0 0;
        width: 100%;
    }

    .login_form {
        background: #002E71;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: column;
    }

    svg.login_form_contaire_close {
        display: block;
    }
    .btn_hide_mobile {
        display: none !important;
    }

    .home_page.signup_page {
        max-width: inherit;
        padding: 25px;
    }

    .home_page {
        padding: 25px;
        display: flex;
        height: initial;
    }
    
    .home_flex {
        flex-direction: column;
        height: initial;
        justify-content: flex-start;
    }
    
    .home_welcome h1 {
        font-size: 23px;
    }
    
    .home_welcome > p {
        font-size: 13px;
        padding: 0;
        margin-bottom: 30px;
    }
    
    .home_action {
        margin-bottom: 30px;
    }
    
    .test_form {
        max-width: initial;
        width: 100%;
        padding: 20px;
    }
    
    .diviser {
        margin: 20px 0;
    }
    
    .test_form p + input.send_request_input {
        margin-top: 15px;
    }
    
    span.send_request_info {
        margin-bottom: 30px;
    }

    .sidebar {
        flex: 0 0 0;
        margin-bottom: 40px;
    }
}




/* REPORTS */
span.report_id {
    font-size: 14px;
    color: #696969;
    margin-bottom: 5px;
    display: block;
}

h1.report_title {
    margin: 0;
    color: #002e71;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
}

.report_header_details {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

span.report_deliverable_true {
    font-size: 14px;
    font-weight: 500;
    color: #18871c;
    background: #c3ddc2;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid rgb(24 135 28 / 25%);
}
span.report_deliverable_false {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #bf0d00;
    background: #eacdcb;    
    border: 1px solid #bf0d0040;
}

.report_score {
    display: flex;
    align-items: center;
}

span.report_value {
    font-weight: 600;
    font-size: 35px;
    line-height: 24px;
    padding: 10px;
    border-radius: 5px;
}
.report_score_red {
    color: #bf0d00;
    background: #eacdcb;    
    border: 1px solid #bf0d0040;
}
.report_score_orange {
    color: #d08009;
    background: rgb(208 128 9 / 14%);
    border: 1px solid rgb(208 128 9 / 25%);
}
.report_score_green {
    color: #18871c;
    background: #c3ddc2;   
    border: 1px solid rgb(24 135 28 / 25%);
}

.report_score_label {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.report_score_label label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    color: #626262;
}

.report_score_label span {
    color: #858585;
    font-size: 13px;
    max-width: 430px;
}

.report_deliv_container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

span.report_deliv_expl {
    color: #858585;
    font-size: 13px;
}

.report_informations {
    padding-top: 30px;
}

.report_informations h2 {
    font-weight: 500;
    font-size: 17px;
    margin: 0 0 20px 0;
}

.report_info {
    display: flex;
    margin-bottom: 12px;
}

.report_info label {
    flex: 0 0 260px;
    color: #626262;
    font-size: 14px;
}

.report_info span {
    font-weight: 500;
    font-size: 14px;
    color: #002e71;
}

.social_detail {
    height: 52px;
    width: calc((100% - ( 20px * 2)) / 3);
    border: 1px solid #a0a0a085;
    border-radius: 6px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.social_details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 600px;
}

.social_info {
    display: flex;
    flex-direction: column;
}

span.social_name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    color: #020287;
    margin-bottom: 2px;
}

span.social_value {
    font-weight: 500;
    font-size: 14px;
    color: #bf0d00;
}

.social_img {
    background: white;
    height: 100%;
    width: 40px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #a7adc6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 56%;
    padding: 8px;
}

.btn_blue.btn_outline.btn_switch {
    padding: 2px;
}

.btn_blue.btn_outline.btn_switch span {
    flex: 0 0 50%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_blue.btn_outline.btn_switch span.active {
    background: #002e71;
    color: #f1eeee;
    border-radius: 36px;
}

.social_img svg {
    height: 100%;
    width: 100%;
    fill: #002E71;
}

.result_report {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
}

span.social_value.social_value_true {
    color: #18871c;
}

.report_info_bool {
    display: flex;
    align-items: center;
    padding: 2px 10px 2px 2px;
    border: 1px solid #a0a0a0;
    border-radius: 5px;
}

.report_info_bool svg {
    margin-right: 8px;
    height: 21px;
    width: 21px;
}

.report_info_bool label {
    color: #626262;
    font-size: 14px;
}

.report_info_bool_flex {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.report_informations p {
    margin: -10px 0 25px 0;
    font-size: 14px;
    color: #626262;
}

.report_params {
    display: flex;
    gap: 46px;
    margin-top: 25px;
}

.report_label {
    font-weight: 500;
    font-size: 13px;
}

span.social_value.social_value_null {
    color: #626262;
}

span.report_subtitle {
    color: #626262;
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
}

.report_info span.report_info_additional {
    margin-left: 10px;
    color: #626262;
}

h3.content_table_subtitle {
    display: flex;
    max-width: 860px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #696969;
    padding-left: 8px;
}

.username_info a {
    color: #fff;
}

.users_flex_table {
    display: flex;
    gap: 12px;
}

.users_flex_table span {
    margin: 0;
    cursor: pointer;
}
.users_flex_table span:last-child {
    color: #bf0d00;
}
.users_flex_table span:first-child {
    color: #002e71;
}

.table_users td:nth-child(1) {
    width: 230px;
}

.table_users td:nth-child(3) {
    width: 130px;
}

.users_form {
    display: flex;
    max-width: 860px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 10px;
}

.users_form input {
    border: 1px solid #002E71;
    border-radius: 8px;
    height: 36px;
    padding: 0 10px;
    background: transparent;
    width: 100%;
}

.users_form_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background: #002e71;
    border-radius: 8px;
    color: #fff;
    font-size: 13px;
    padding-bottom: 1px;
    font-weight: 500;
    cursor: pointer;
}

.users_flex {
    display: flex;
    align-items: center;
    border: 1px solid #002E71;
    border-radius: 8px;
    padding: 4px;
    height: 36px;
    gap: 4px;
}

.users_flex span {
    height: 100%;
    display: flex;
    align-items: center;
    color: #002e71;
    font-size: 13px;
    font-weight: 500;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
}

.users_flex span.active {
    color: #fff;
    background: #002e71;
}



span.api_log_success {
    color: #18871c;
}

span.api_log_fail {
    color: #bf0d00;
}




.api_log_dates span, .api_log_show_dates {
    font-size: 12px;
    color: #696969;
}

.api_log_show_dates {
    cursor: pointer;
    margin: 0 0 0 5px;
    position: relative;
    top: 1px;
}


span.report_error_result {
    font-size: 14px;
    color: #002e71;
    font-weight: 500;
    cursor: pointer;
}

span.report_deliverable_null {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #626262;
    background: rgb(98 98 98 / 25%);
    border: 1px solid #62626240;
}
.content_table_title_flex {
    display: flex;
    justify-content: space-between;
    max-width: 860px;
    margin: 0 auto 16px auto;
    align-items: center;
}

.content_table_title_flex h3.content_table_subtitle {
    margin: 0;
}

.content_table_switch {
    font-weight: 600;
    font-size: 14px;
    padding: 3px;
    height: 32px;
    border: 1px solid #002E71;
    background: transparent;
    color: #002E71;
    display: inline-flex;
    align-items: center;
    border-radius: 36px;
    gap: 3px;
}

.content_table_switch span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 12px;
    cursor: pointer;
}

.content_table_switch span:hover {
    background: #d4d5ee;
}

.content_table_switch span.active {
    background: #002E71;
    color: #f1eeee;
}
.duplicates_dropdown {
    display: flex;
    justify-content: center;
    line-height: 1;
}

td.api_log_cell_border {
    border-left: 1px solid #002E71;
}

td.api_log_cell_border + td.api_log_cell_border {
    border-right: 1px solid #002E71;
}

.btn_loading_container {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.btn_loading_blue {
    padding: 0 24px;
    height: 36px;
    background: #002E71;
    color: #fff;
    display: inline-flex;
    align-items: center;
    border-radius: 36px;
    font-size: 15.6px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.btn_loading_blue.btn_loading_blue_loading {
    cursor: wait;
    background: hsl(216deg 100% 22% / 60%);
}